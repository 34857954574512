<template>
  <div class="app flex-row">
    <div class="container-fluid">

      <b-row>
        <b-col md="9" class="img-promocion-custom d-none d-md-block" :style="{ backgroundImage: 'url(' + fondo + ')'}">
        </b-col>

        <b-col md="3" class="pl-0 pr-0 container-login-custom">
          <div class="container-logo-custom">
            <b-img :src="logo" class="logo-custom" />
          </div>

          <b-card no-body class="mb-0 card-custom">
            <b-card-body class="card-body-custom">
              <b-form class="align-items-center">
                <h1 class="custom-h1">Recuperar Contraseña</h1>

                <b-form-group label="Email">
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-user"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="email" class="form-control" placeholder="Email" autocomplete="email" v-model="form.email"/>
                  </b-input-group>
                </b-form-group>
                <b-row>
                  <b-col md="12">
                    <b-button variant="primary" class="px-4 submit-custom primary-background" v-on:click="recovery()">RECUPERAR</b-button>
                  </b-col>
                </b-row>               
                 
                <b-row>                
                  <b-col md="12" class="text-center">
                    <b-button variant="link" class="px-0 primary-color" @click="ir('login')">Loguearme</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import serviceAPI from './services'
  import {EventBus} from '@/handler/event-bus'

  export default {
    data () {
      return {
        logo: '',
        fondo: '',
        form: {
          email: '',
        }
      }
    },
    created () {
      EventBus.$on('Settings:settings', data => {
        this.setup()
      })
    },
    mounted() {
      this.setup()
    },
    methods: {
      setup () {
        if(Session.getSession().settings) {
          this.logo = Session.getSession().settings.logo
          this.fondo = Session.getSession().settings.image_login
        }
      },
      ir(path) {
        if(path == 'login') {
          this.$router.push({ name: 'Login' })
        }
      },
      recovery () {
        let loader = this.$loading.show();

        var result = serviceAPI.recoveryPassword(this.form)        

        result.then((response) => {
          var data = response.data

          loader.hide()
          
          if(data){
            this.$awn.success("Se envío un email a su correo con su nueva clave. (Si el correo no aparece en la bandeja de entrada, revise el spam)")

            this.ir('login')
          } else {
            this.$awn.success("Ups!! Ocurrió un error! Intente más tarde!")
          }
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      }
    },
  }
</script>

<style>
  .img-promocion-custom {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
    z-index: 10;
    background-color: rgba(0,0,0,0.8);
    filter: brightness(0.4);
  }
  .card-custom {
    width: 100%;
    padding-top: 15%;
    padding-left: 15%;
    padding-right: 15%;
    border: none;
  }
  .card-body-custom {
    padding: 0px;
  }
  .custom-h1{
    font-weight: 100;
  }
  .container-logo-custom{
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 20%;
    background: #ffff;
  }
  .logo-custom{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 75px;
    max-width: 100%;
    object-fit: contain;   
  }
  .submit-custom {
    width: 100%;
  }
  .container-login-custom {
    height: 100vh;
    background: #ffffff;
  }
</style>
